// Function to return an array of menu items for the supplier side menu.
export const SideMenuSupplierUpgrade = () => {
  return [
    {
      // Dashboard menu item
      title: "Dashboard",
      name: "Dashboard",
      parent: false,
      adopt: "",
      icon: "i-Bar-Chart",
      link: "/DashboardHome",
    },
    {
      // My Company menu item
      title: "My Company",
      name: "My Company",
      parent: true,
      adopt: "",
      icon: "company",
      child: [
        {
          // Renew Subscription sub-item under My Profile
          title: "Subscription",
          name: "Subscription",
          parent: true,
          link: "/RenewSubscription",
        },
      ],
    },
    {
      // My Brands menu item
      title: "My Brands",
      name: "My Brands",
      parent: false,
      adopt: "",
      icon: "brand",
      link: "/ManageBrands",
    },
    {
      // My Products menu item
      title: "My Products",
      name: "My Products",
      parent: false,
      adopt: "",
      icon: "product",
      link: "/ProductsPage",
    },
    {
      // Product Browser menu item
      title: "Product Browser",
      name: "Product Browser",
      parent: true,
      adopt: "",
      icon: "product-browse",
      link: "/productBrowser",
    },
    {
      // My Profile menu item with sub-items
      title: "My Profile",
      name: "My Profile",
      parent: true,
      adopt: "",
      icon: "profile",
      child: [
        {
          // My Details sub-item under My Profile
          title: "My Details",
          name: "My Details",
          parent: true,
          link: "/User/UserProfile",
        },
        {
          // Logout sub-item under My Profile
          title: "logout",
          name: "logout",
          parent: true,
          link: "/",
        },
      ],
    },
  ];
};
