export const SideMenuRetailerNew = () => {
    return [
        {
            title: "Dashboard",
            name: "Dashboard",
            parent: true,
            adopt: "",
            icon: 'i-Bar-Chart',
            link: '/DashboardHome',
        },
        // {
        //     title: "My Company",
        //     name: "My Company",
        //     parent: false,
        //     adopt: "",
        //     icon: 'company',
        //     link: '/Account-Details',
        // },
        // {
        //     title: "Product Browser",
        //     name: "Product Browser",
        //     parent: true,
        //     adopt: "",
        //     icon: "product-browse",
        //     link: "/productBrowser"
        // },
        {
            title: "Retailer",
            name: "Retailer",
            parent: true,
            adopt: "",
            icon: "list",
            child: [
                {
                    title: "menu A",
                    name: "menu A",
                    parent: true,
                    link: "/ActionMenuA1",
                },
                {
                    title: "Menu B",
                    name: "Menu B",
                    parent: true,
                    link: "/ActionMenuB2",
                },
                {
                    title: "Menu C",
                    name: "Menu C",
                    parent: true,
                    link: "/ActionMenuC3",
                }
            ]
        },
        {
            title: "My Profile",
            name: "My Profile",
            parent: true,
            adopt: "",
            icon: "profile",
            child: [
                {
                    title: "My Details",
                    name: "My Details",
                    parent: true,
                    link: "/User/UserProfile",
                },
                {
                    title: "logout",
                    name: "logout",
                    parent: true,
                    link: "/",
                }
            ]
        },
    ]
}
