export const SideMenuUser = () => {
    return [
        {
            title: "Get Started",
            name: "Get Started",
            parent: true,
            adopt: "",
            icon: 'i-Bar-Chart',
            link: '/DashboardHome',
        },
        {
            title: "My Profile",
            name: "My Profile",
            parent: true,
            adopt: "",
            icon: "profile",
            child: [
                {
                    title: "logout",
                    name: "logout",
                    parent: true,
                    link: "/",
                }
            ]
        },
    ]
}

