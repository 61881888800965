import React, { useContext, useState } from 'react';
import { DetailModel, GroupModel, GVDEditorAPI, VariantModel } from './GVDEditorAPI';
import GVDItem from './GVDItem';
import { useAppDispatch } from "../../../../../store/store";
import {
  onRenameDetail,
  onDeleteDetail
} from "../../../../../store/slices/gvdEditiorSlice";

type GVDItemDetailProps = {
  group: GroupModel,
  variant: VariantModel,
  detail: DetailModel
}

const GVDItemDetail = ({ detail, variant }: any) => {
  const dispatch = useAppDispatch();

  const onRename = (description: string) => {
    dispatch(onRenameDetail(description, detail.DetailID, variant.VariantID));
  };

  const onDelete = async () => {
    dispatch(onDeleteDetail(detail.DetailID, variant.VariantID))
  };

  return (
    <GVDItem
      description={detail.DetailDesc}
      onAddNew={false}
      onRename={onRename}
      onDelete={onDelete} />
  );
};

export default GVDItemDetail;