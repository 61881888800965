import React, { Fragment, useEffect, useState } from "react";
import { HTTP_STATUS_CODES } from "../../../api/API";
import { getCompaniesAssignedToPrefix } from "../../../api/BarcodeScan";
import DashboardPopUp from "../../../dashboard-components/DashboardPopUp";
import { Transition, Dialog } from "@headlessui/react";

const PrefixModal = ({ prefix, showPrefixModal, closePrefixModal }) => {
    const [loading, setLoading] = useState(false);

    const [companies, setCompanies] = useState(null);

    const searchCompanies = async () => {
        setLoading(true);

        const res = await getCompaniesAssignedToPrefix(prefix.ID);

        setLoading(false);

        if (res.status !== HTTP_STATUS_CODES.OK) return false;

        setCompanies(res.data);
    };

    useEffect(() => {
        searchCompanies();
    }, []);

    return (
        // <DashboardPopUp processing={loading} header={"Companies using prefix - " + prefix.Prefix}>
        // {companies?.map((value, index) => (
        //   <CompanyDescContainer>
        //     <div><span>ID:</span> {value.CompanyID}</div>
        //     <div><span>GLN:</span> {value.EntityGLN ?? 'NOT DEFINED'}</div>
        //     <div><span>Name:</span> {value.CompanyName}</div>
        //   </CompanyDescContainer>
        // ))}
        // </DashboardPopUp>

        <Transition show={showPrefixModal} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[9999]"
                onClose={closePrefixModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white py-6 px-3 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-between border-b pb-3 border-b-black items-start">
                                    <h5 className="font-semibold text-sm">
                                        Companies using prefix - {prefix.LicenseKey}
                                    </h5>
                                    <div
                                        onClick={closePrefixModal}
                                        className="cursor-pointer"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="mt-3 flex flex-col gap-2">
                                    {companies?.map((value, index) => (
                                        <>
                                            <div>
                                                <span>ID:</span>{" "}
                                                {value.CompanyID}
                                            </div>
                                            <div>
                                                <span>GLN:</span>{" "}
                                                {value.EntityGLN ??
                                                    "NOT DEFINED"}
                                            </div>
                                            <div>
                                                <span>Name:</span>{" "}
                                                {value.CompanyName}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default PrefixModal;
