import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext, useAuthState } from "../../context/AuthContext";
import { SideMenuHome } from "./SideMenuHome";
import { SideMenuSupplierUpgrade } from "./SideMenuSupplierUpgrade";
import { SideMenuRetailerNew } from "./SideMenuRetailer";
import Footer from "../../components/footer/Footer";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { SideMenuUser } from "./SideMenuUser";
import { currentDate } from "../../store/slices/authSlice";
import { useAppDispatch } from "../../store/store";
import { SideMenuEmployee } from "./SideMenuEmployee";
// import 'react-pro-sidebar/dist/css/styles.css';

const SideMenu = ({ toggle, setToggle }) => {
  const [activeName, setActiveName] = useState("");
  const [renewSubscription, setRenewSubscription] = useState(false);
  const userAuth = useAuthContext();
  const authState = useAuthState();
  const dispatch = useAppDispatch();
  const logout = async () => authState.logoutUser();
  const [openSubMenu, setOpenSubMenu] = useState([]);
  const userSubscription = userAuth.subscription;
  const targetDate = new Date(userSubscription?.DateTo);
  const activeLink = window.location.pathname;

  useEffect(() => {
    const data = async () => {
      await dispatch(currentDate()).then((res) => {
        const timestamp = Date.parse(res?.Current_DateTime);
        if (timestamp) {
          const currentDate = new Date(timestamp);
          setRenewSubscription(targetDate <= currentDate);
        }
      });
    };
    data();
  }, [dispatch]);

  const getMenuToDisplay = () => {
    if (!userAuth.isUserLoggedIn) {
      return SideMenuHome().map((item, index) => generateMenu(item, index));
    }

    // New User Sidebar Menu
    if (userAuth.isNewUser) {
      return SideMenuUser().map((item, index) => generateMenu(item, index));
    }

    // Supplier Sidebar Menu
    if (!userAuth.isNewUser && userAuth.isSupplier) {
      return SideMenuSupplierUpgrade().map((item, index) =>
        generateMenu(item, index)
      );
    }

    // Employee Sidebar Menu
    if (!userAuth.isNewUser && userAuth.isEmployee) {
      return SideMenuEmployee().map((item, index) => generateMenu(item, index));
    }

    // Retailer Sidebar Menu
    if (!userAuth.isNewUser && userAuth.isRetailer) {
      return SideMenuRetailerNew().map((item, index) =>
        generateMenu(item, index)
      );
    }
    return SideMenuSupplierUpgrade().map((item, index) =>
      generateMenu(item, index)
    );
  };

  const boxShadowStyle = {
    boxShadow: "0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04)",
  };

  const renderIcon = (item) => {
    if (item.icon) {
      if (item.icon === "list") {
        return (
          <svg
            className="w-5 h-5 text-lg mr-2 text-muted"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M5 7h14M5 12h14M5 17h14"
            />
          </svg>
        );
      } else if (item.icon === "arrow-right") {
        return (
          <svg
            className="w-3 h-5 text-lg mr-2 text-muted"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m9 5 7 7-7 7"
            ></path>
          </svg>
        );
      } else if (item.icon === "company") {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 text-lg mr-2 text-muted"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
            />
          </svg>
        );
      } else if (item.icon === "product-browse") {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 text-lg mr-2 text-muted"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25"
            />
          </svg>
        );
      } else if (item.icon === "brand") {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 text-lg mr-2 text-muted"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"
            />
          </svg>
        );
      } else if (item.icon === "product") {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 text-lg mr-2 text-muted"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
            />
          </svg>
        );
      } else if (item.icon === "profile") {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-5 w-5 text-lg mr-2 text-muted"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </svg>
        );
      } else {
        return <i className={`${item.icon} text-lg mr-2 text-muted`}></i>;
      }
    } else {
      return null;
    }
  };

  const generateMenu = (item, index, recursive = 0) => {
    if (item.child) {
      return (
        <SubMenu
          key={index}
          label={
            <>
              {renderIcon(item)}
              {/* <span className="item-name text-base text-muted">{item.name}</span> */}
              <span className="item-name text-base text-muted">
                {item.name}
              </span>
            </>
          }
          onOpenChange={handleOpenChange(item.name)}
          className={openSubMenu.indexOf(item.name) > -1 ? "subMenu-bg" : ""}
        >
          {item.child.map((value, idx) =>
            generateMenu(value, idx, recursive + 1)
          )}
        </SubMenu>
      );
    }

    return (
      <>
        {item.name === "logout" ? (
          <MenuItem onClick={logout}>
            <div className="item-name text-base text-muted ">Logout</div>
          </MenuItem>
        ) : (
          <MenuItem
            key={index}
            component={
              <Link to={item.link} onClick={() => handleNavigate(item.name)} />
            }
            className={activeLink === item.link ? "mm-active" : ""}
          >
            {renderIcon(item)}
            <span className="item-name text-base text-muted">
              {item.title}{" "}
            </span>
          </MenuItem>
        )}
      </>
    );
  };

  const handleNavigate = (path) => {
    setActiveName(path);
  };

  const handleOpenChange = (subMenuKey) => () => {
    if (openSubMenu.length && openSubMenu.indexOf(subMenuKey) > -1) {
      const arrayIndex = openSubMenu.indexOf(subMenuKey);
      const itemsCopy = Array.from(openSubMenu);
      itemsCopy.splice(arrayIndex, 1);
      setOpenSubMenu(itemsCopy);
    } else {
      setOpenSubMenu([...openSubMenu, subMenuKey]);
    }
  };

  return (
    <>
      <div
        className={`h-screen fixed top-0 ${
          toggle ? "-left-60" : "left-0"
        } w-60 z-[1000] bg-white transition-all duration-200 ease-in`}
        style={boxShadowStyle}
      >
        {/* Company Logo */}
        <div className="pr-3 text-center relative mt-4 mb-2 flex justify-center items-center">
          <div
            onClick={() => setToggle(true)}
            className="cursor-pointer absolute top-0 right-0 w-12 h-7 flex justify-center items-center border rounded bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="black"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>
          <img
            className="pl-3 w-full object-contain"
            src="/BBA-Logo-TBl.png"
            alt="Logo"
          />
        </div>
        {/* Company Logo */}

        {/* Menus */}
        <div className="h-[calc(100vh-130px)] flex justify-between flex-col">
          <div className="side-nav">
            <div className="main-menu">
              <Sidebar>
                <Menu>{getMenuToDisplay()}</Menu>
              </Sidebar>
            </div>
          </div>
          <Footer login={authState.isUserLoggedIn} />
        </div>
        {/* Menus */}
      </div>

      {/* Toggle Btn */}
      <div
        onClick={() => setToggle(false)}
        className="cursor-pointer flex fixed left-0 bg-gray-200 rounded w-12 mt-2 justify-center items-center
                h-5 text-black top-0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="black"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </div>
      {/* Toggle Btn */}
    </>
  );
};

export default SideMenu;
