import React, { useContext, useState } from 'react';
import GVDItem from './GVDItem';
import GVDItemVariant from './GVDItemVariant';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';
import { GroupModel, GVDEditorAPI, VariantModel } from './GVDEditorAPI';
import { useAppSelector, useAppDispatch } from "../../../../../store/store";
import {
	gvdEditior,
  fetchGroupVars,
  addNewVariant,
  onRenameGroup,
  onDeleteGroup
} from "../../../../../store/slices/gvdEditiorSlice";

type GVDItemGroupProps = {
  group: GroupModel
}

const GVDItemGroup = ({ group }: any) => {
  const dispatch = useAppDispatch();
  const { variants } = useAppSelector(gvdEditior);
  const { BRICK } = useContext(ContextSelectedBrick);
  const groupTotal = variants.length;

  const onAddNew = (description: string) => {
    dispatch(addNewVariant(description, group.GroupingID))
    dispatch(fetchGroupVars(BRICK, group.GroupingID));
  };

  const onRename =  (description: string) => {
    dispatch(onRenameGroup(description, group.GroupingID));
  
  };

  const onDelete = () => {
    dispatch(onDeleteGroup(group.GroupingID))
  };
  const fetchSubItems = () => {
    dispatch(fetchGroupVars(BRICK, group.GroupingID));
  };
  return (
    <GVDItem
      description={group.GroupingDesc}
      fetchSubItems={fetchSubItems}
      onAddNew={onAddNew}
      onRename={onRename}
      onDelete={groupTotal > 0 ? false : onDelete}>

{(Array.isArray(variants[group.GroupingID]) ? variants[group.GroupingID] : []).map((variant, key) => (
  <GVDItemVariant key={key} group={group} variant={variant} />
))}

    </GVDItem>
  );
};

export default GVDItemGroup;