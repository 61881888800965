import * as React from 'react';
import { useForm } from 'react-hook-form';
import { ContextSelectedBrick } from '../contexts/ContextSelectedBrick';
import {
  brickSearch,
  gvdEditior
} from "../../../../../store/slices/gvdEditiorSlice";
import { useAppSelector, useAppDispatch } from "../../../../../store/store";

const BrickSearchContainer = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(gvdEditior);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();

  const { BRICK, BrickDesc, setSelectedBrick, setSelectedBrickDesc } = React.useContext(ContextSelectedBrick);

  const searchBrick = () => {
    if (watch('brick').toString().trim().length === 0) {
      return false;
    }
    setSelectedBrick(watch('brick'));
  }

  React.useEffect(() => {
    if (!BRICK.trim().length) return;
    searchBrickDetails();
  }, [BRICK]);

  const searchBrickDetails = async () => {
    const res = await dispatch(brickSearch(BRICK));

    setValue('brick', '');
    if (res.data?.bricks.length === 0) return;
    // setSelectedBrick(res.data.bricks[0].BRICK.toString());
    setSelectedBrickDesc(res.data?.bricks[0].BrickDesc);
  };

  return (
    <div className="flex flex-row items-center">
      <span className="block flex-grow text-base font-semibold self-center">
        {loading && <>Loading...</>}
        {!loading && BRICK === null && watch('brick').trim().length > 0 && <>Brick not found</>}
        {!loading && BRICK !== null && <>Brick: {BRICK ? BRICK + ' - ' + BrickDesc : ''}</>}
      </span>

      <div>
        <form onSubmit={handleSubmit(searchBrick)} className='flex gap-3 items-center'>
          <input {...register('brick', { required: true })} placeholder='Enter brick' defaultValue="10000025" className="rounded mr-2 bg-white p-2 border border-black" type="text" />
          <button className="bg-primary px-3 py-2 hover:bg-lightPrimary text-white rounded">Search Brick</button>
        </form>
      </div>
    </div>
  );
};

export default BrickSearchContainer;