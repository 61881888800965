import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";
import { getBrickDetails } from '../../api/GroupVariantDetail';
import { GVDEditorAPI } from '../../private-pages/Admin/Categories/GVD/GVDEditor/GVDEditorAPI';

export interface GvdEditiorState {
  variants: any;
  groups:  any;
  details: any;
  action: any;
  brick: any;
  brickDesc: any;
  processing: boolean;
  showItemSub: boolean;
  hasRequestedData: boolean;
  loading: boolean;
  errorMsg: object;
}

export const initialState: GvdEditiorState = {
  variants: {},
  details: {},
  brick: '',
  action: '',
  brickDesc: '',
  processing: false,
  showItemSub: false,
  hasRequestedData: false,
  loading: false,
  errorMsg: null,
  groups: null,
};

export const gvdEditiorSlice = createSlice({
  name: "gvdEditior",
  initialState,
  reducers: {
    setErrorMsg: (state: GvdEditiorState, { payload }: PayloadAction<any>) => {
      state.errorMsg = payload;
    },
    setBrick: (state: GvdEditiorState, { payload }: PayloadAction<any>) => {
      state.brick = payload;
    },
    setLoading: (state: GvdEditiorState, { payload }: PayloadAction<any>) => {
      state.loading = payload;
    },
    setBrickDesc: (state: GvdEditiorState, { payload }: PayloadAction<any>) => {
      state.brickDesc = payload;
    },
    setVariants: (state: GvdEditiorState, { payload }: PayloadAction<any>) => {
      state.variants[payload.groupId] = payload.variants;
    },
    setHasRequestedData: (state: GvdEditiorState, { payload }: PayloadAction<any>) => {
      state.hasRequestedData = payload;
    }, 
    setShowItemSub: (state: GvdEditiorState, { payload }: PayloadAction<any>) => {
      state.showItemSub = payload;
    },
    setProcessing: (state: GvdEditiorState, { payload }: PayloadAction<any>) => {
      state.processing = payload;
    },
    setAction: (state: GvdEditiorState, { payload }: PayloadAction<any>) => {
      state.action = payload;
    },
    setDetails: (state: GvdEditiorState, { payload }: PayloadAction<any>) => {
      state.details[payload.variantId] = payload.details;
    },
    setGroups: (state: GvdEditiorState, { payload }: PayloadAction<any>) => {
      state.groups = payload;
    },
  },
});

export const brickSearch = (BRICK) => async (dispatch: any) => {
  dispatch(setLoading(true));
  const json = await getBrickDetails(BRICK);
  dispatch(setLoading(false));
  return json;
};

export const fetchGroups = (BRICK) => async (dispatch: any) => {
  dispatch(setGroups(null))
  const json = await GVDEditorAPI.getGroups(BRICK);
    if (json.status === 200) {
      dispatch(setGroups(json.data))
    } else {
      return;
    }
};

export const addNewGroup = (description, BRICK) => async (dispatch: any, getState: () => RootState) => {
  const json = await GVDEditorAPI.newGroup(description, BRICK);
  if (json.status === 201) {
    const updatedGroups = [...getState().gvdEditior.groups];
    updatedGroups.push(json.data);
    dispatch(setGroups(updatedGroups));
  } else {
    return;
  }
};

export const onRenameGroup = (description, GroupingID) => async (dispatch: any, getState: () => RootState) => {
  const json = await GVDEditorAPI.renameGroup(GroupingID, description);

  if (json.status === 200) {
    const state = getState();
    const updatedGroups = state.gvdEditior.groups.map((group) =>
      group.GroupingID === GroupingID ? { ...group, GroupingDesc: description } : group
    );
    dispatch(setGroups(updatedGroups));
  }

  return json;
};

export const onDeleteGroup = (GroupingID) => async (dispatch: any, getState: () => RootState) => {
  const json = await GVDEditorAPI.deleteGroup(GroupingID);
  if (json.status === 200) {
    const updatedGroups = getState().gvdEditior.groups.filter((group) => group.GroupingID !== GroupingID);
    dispatch(setGroups(updatedGroups));
  } else {
    return;
  }
};

export const fetchGroupVars = (BRICK, GroupingID) => async (dispatch: any) => {
  const json = await GVDEditorAPI.getGroupVars(BRICK, GroupingID);
    if (json.status === 200) {
      dispatch(setVariants({ groupId: GroupingID, variants: json.data }));
    } else {
      return;
    }
  return json;
};

export const addNewVariant = (description, GroupingID) => async (dispatch: any, getState: () => RootState) => {
  const json = await GVDEditorAPI.newVariant(description, GroupingID);
  if (json.status === 201) {
    const variants = getState().gvdEditior.variants;
    const groupHasVariants = Object.keys(variants).some((i) => i === GroupingID);
    if (!groupHasVariants) {
      // If the group doesn't have variants, initialize an empty object for it
      const copy = { ...variants };
      copy[GroupingID] = {};
      dispatch(setVariants({ groupId: GroupingID, variants: copy[GroupingID] }));
    }
    // Create a new object for the variant with its VariantID as the key
    const updatedVariants = { ...getState().gvdEditior.variants[GroupingID] };
    updatedVariants[json.data.VariantID] = json.data;
    
    dispatch(setVariants({ groupId: GroupingID, variants: updatedVariants }));
  } else {
    return;
  }
};


export const onRenameVariant = (description, VariantID, GroupingID) => async (dispatch: any, getState: () => RootState) => {
  const json = await GVDEditorAPI.renameVariant(VariantID, description);
  if (json.status === 200) {
    const state = getState();
    const existingVariants = state.gvdEditior.variants[GroupingID] || [];
    const updatedVariants = existingVariants.map((variant) =>
      variant.VariantID === VariantID ? { ...variant, VariantDesc: description } : variant
    );
    dispatch(setVariants({ groupId: GroupingID, variants: updatedVariants }));
  }

  return json;
};

export const onDeleteVariant = (groupId, VariantID) => async (dispatch: any, getState: () => RootState) => {
  const json = await GVDEditorAPI.deleteVariant(VariantID);
  if (json.status === 200) {
    const updatedVariants = getState().gvdEditior.variants[groupId].filter((variant) => variant.VariantID !== VariantID);
    dispatch(setVariants({ groupId, variants: updatedVariants }));
  } else {
    return;
  }
};

export const fetchVariantData = (BRICK, GroupingID, VariantID) => async (dispatch: any) => {
  const json = await GVDEditorAPI.getGroupVarDetails(BRICK, GroupingID, VariantID);
    if (json.status === 200) {
      dispatch(setDetails({ variantId: VariantID, details: json.data }));
    } else {
      return;
    }
  return json;
};

export const addNewVariantDetail = (description, VariantID) => async (dispatch: any, getState: () => RootState) => {
  const json = await GVDEditorAPI.newDetail(description, VariantID);
  if (json.status === 201) {
    const details = getState().gvdEditior.details;
    const variantHasDetails = Object.keys(details).some((i) => i === VariantID);
    if (!variantHasDetails) {
      const copy = { ...details };
      copy[VariantID] = [];
      dispatch(setDetails({ variantId: VariantID, details: copy[VariantID] }));
    }
    const updatedDetails = Array.isArray(details[VariantID]) ? [...details[VariantID]] : [];
    updatedDetails.push(json.data);
    dispatch(setDetails({ variantId: VariantID, details: updatedDetails }));
  } else {
    return;
  }
};

export const onRenameDetail = (description, DetailID, VariantID) => async (dispatch: any, getState: () => RootState) => {
  const json = await GVDEditorAPI.renameDetail(DetailID, description);
  if (json.status === 200) {
    const state = getState();
    const existingDetails = state.gvdEditior.details[VariantID] || [];
    const updatedDetails = existingDetails.map((detail) =>
      detail.DetailID === DetailID ? { ...detail, DetailDesc: description } : detail
    );
    
    dispatch(setDetails({ variantId: VariantID, details: updatedDetails }));
  }

  return json;
};

export const onDeleteDetail = (DetailID, variantId) => async (dispatch: any, getState: () => RootState) => {
  const json = await GVDEditorAPI.deleteDetail(DetailID);
  if (json.status === 200) {
    const updatedDetails = getState().gvdEditior.details[variantId].filter((detail) => detail.DetailID !== DetailID);
    dispatch(setDetails({ variantId, details: updatedDetails }));
  } else {
    return;
  }
};

export const {
  setBrick,
  setLoading,
  setErrorMsg,
  setBrickDesc,
  setVariants,
  setHasRequestedData,
  setShowItemSub,
  setProcessing,
  setAction,
  setDetails,
  setGroups
} = gvdEditiorSlice.actions;

export const gvdEditior = (state: RootState) => state.gvdEditior;

export default gvdEditiorSlice.reducer;
