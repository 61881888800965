import React from "react";
import JiraLogo from "../../assets/images/Jira_Logo.svg";
import confluenceLogo from "../../assets/images/Confluence_logo.svg";

const TemplatePage = () => {
  return (

    <div style={{ backgroundColor: "white", minHeight: "100vh", padding: "20px" }}>
        <div style={{ margin: "20px", fontFamily: "Arial, sans-serif" }}>
        
        {/* Flexbox Table */}
        <div
            style={{
            display: "flex",
            flexDirection: "column",
            // border: "1px solid black",
            width: "90%",
            margin: "0 auto",
            }}
        >

        {/* Row 2: Page Heading */}
            <div
            style={{
                textAlign: "left",
                borderBottom: "0px solid black",
                padding: "20px"
            }}
            >
            <h1
            style={{
                margin: 0,
                fontFamily: "'Roboto', sans-serif", // Use Roboto font with a fallback to sans-serif
                fontSize: "2.5rem", // Explicitly set the font size
                fontWeight: "bold", // Explicitly set the font weight
                fontVariant: "small-caps", // Enable small caps
                textTransform: "uppercase", // Ensure all text is uppercase
                letterSpacing: "0.15rem", // Add spacing for a polished look
            }}
            >
            Page Heading
            </h1>
            </div>

        {/* Row 3: Page Summary */}
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
          <div style={{ flex: "25%", textAlign: "right", fontWeight: "bold", padding: "10px" }}>Page Summary:</div>
          <div style={{ flex: "75%", textAlign: "left", padding: "10px" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus luctus urna sed urna ultricies ac tempor dui sagittis. In condimentum facilisis porta. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </div>
        </div>

        {/* Row 4: Page Details */}
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
          <div style={{ flex: "25%", textAlign: "right", fontWeight: "bold", padding: "10px" }}>Page Details:</div>
          <div style={{ flex: "75%", textAlign: "left", padding: "10px" }}>
            Lorem ipsum... explain what the page is for, couple of paragraphs...
            <br /><br />
            Consectetur adipiscing elit... this combined with the feature bullet list should explain the purpose and functionality of the page...
            <br /><br />
            Vivamus luctus urna... the level of detail does not have to be too much, there is a link to the Confluence page and Jira task for each page so...
          </div>
        </div>
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
          <div style={{ flex: "25%", textAlign: "right", fontWeight: "bold", padding: "10px" }}>Page Details:</div>
          <div style={{ flex: "75%", textAlign: "left", padding: "10px" }}>
            Lorem ipsum... explain what the page is for, couple of paragraphs...
            <br /><br />
            Consectetur adipiscing elit... this combined with the feature bullet list should explain the purpose and functionality of the page...
            <br /><br />
            Vivamus luctus urna... the level of detail does not have to be too much, there is a link to the Confluence page and Jira task for each page so...
          </div>
        </div>
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
          <div style={{ flex: "25%", textAlign: "right", fontWeight: "bold", padding: "10px" }}>Page Details:</div>
          <div style={{ flex: "75%", textAlign: "left", padding: "10px" }}>
            Lorem ipsum... explain what the page is for, couple of paragraphs...
            <br /><br />
            Consectetur adipiscing elit... this combined with the feature bullet list should explain the purpose and functionality of the page...
            <br /><br />
            Vivamus luctus urna... the level of detail does not have to be too much, there is a link to the Confluence page and Jira task for each page so...
          </div>
        </div>
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
          <div style={{ flex: "25%", textAlign: "right", fontWeight: "bold", padding: "10px" }}>Page Details:</div>
          <div style={{ flex: "75%", textAlign: "left", padding: "10px" }}>
            Lorem ipsum... explain what the page is for, couple of paragraphs...
            <br /><br />
            Consectetur adipiscing elit... this combined with the feature bullet list should explain the purpose and functionality of the page...
            <br /><br />
            Vivamus luctus urna... the level of detail does not have to be too much, there is a link to the Confluence page and Jira task for each page so...
          </div>
        </div>

        {/* Row 5: Page Functionality */}
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
        <div style={{ flex: "25%", textAlign: "right", fontWeight: "bold", padding: "10px" }}>
            Page Functionality:
        </div>
        <div style={{ flex: "75%", textAlign: "left", padding: "10px" }}>
            <ul style={{ paddingLeft: "0px", margin: 0 }}>
                <li style={{ marginBottom: "3px" }}>
                    <b>Feature 1: </b>Lorem ipsum dolor sit amet, consectetur.
                </li>
                <li style={{ marginBottom: "3px" }}>
                    <b>Feature 2: </b>Vivamus luctus urna sed urna ultricies.
                </li>
                <li style={{ marginBottom: "3px" }}>
                    <b>Feature 3: </b>In condimentum facilisis porta egestas.
                </li>
                <li style={{ marginBottom: "3px" }}>
                    <b>Feature 4: </b>Ut enim ad minim veniam, quis nostrud.
                </li>
                <li style={{ marginBottom: "3px" }}>
                    <b>Feature 5: </b>Duis aute irure dolor in reprehenderit.
                </li>
            </ul>
        </div>
        </div>

        {/* Row 6: Project Lead */}
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
          <div style={{ flex: "25%", textAlign: "right", fontWeight: "bold", padding: "10px" }}>Project Lead:</div>
          <div style={{ flex: "75%", textAlign: "left", padding: "10px" }}>
            Eddie
          </div>
        </div>

         {/* Links Section */}
        <div style={{ display: "flex", justifyContent: "center", gap: "16px", padding: "16px 0" }}>
        <a
            href="https://trickletreat.atlassian.net/wiki/x/AYClZg"
            target="_confluencewindow"
            rel="noopener noreferrer"
            style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "8px 16px",
            backgroundColor: "white",
            border: "1px solid #e5e7eb",
            margin: "20px",
            borderRadius: "4px",
            textDecoration: "none",
            width: "150px",
            justifyContent: "center"
            }}
        >
            <img src={confluenceLogo} alt="Confluence" style={{ width: "30px", height: "30px" }} />
            <span>Confluence</span>
        </a>
        
        <a
            href="https://trickletreat.atlassian.net/browse/PHS-193"
            target="_jirawindow"
            rel="noopener noreferrer"
            style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "8px 16px",
            margin: "20px",
            backgroundColor: "white",
            border: "1px solid #e5e7eb",
            borderRadius: "4px",
            textDecoration: "none",
            width: "150px",
            justifyContent: "center"
            }}
        >
            <img src={JiraLogo} alt="Jira" style={{ width: "35px", height: "35px" }} />
            <span>Jira</span>
        </a>
        </div>


        {/* Row 8: Specification Details */}
        <div style={{ display: "flex" }}>
          <div style={{ flex: "25%", textAlign: "right", fontWeight: "bold", padding: "10px" }}>Date Created:</div>
          <div style={{ flex: "75%", textAlign: "left", padding: "10px" }}>
            Thursday, 23rd January 2025 by Eddie.
          </div>
        </div>        
      </div>
    </div>
    </div>
    );
};

export default TemplatePage;
