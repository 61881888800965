import React, { Fragment, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';

const GVDItemDeleteModal = ({ onClickYes, onClickNo, closeModal, isOpen }: any) => {

  const [loading, setLoading] = useState(false);

  const callYesClick = async () => {
    setLoading(true);
    await onClickYes();
    setLoading(false);
    closeModal();
  }

  const callNoClick = async () => {
    onClickNo && await onClickNo();
    closeModal();
  }

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className='flex justify-between items-center'>
                  <h6 className='font-semibold text-sm'>Are you sure you want to proceed with the exclusion?</h6>
                  <div onClick={closeModal} className='cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                </div>
                <div className='flex items-center gap-2 mt-3'>
                  <button onClick={callYesClick} className='bg-primary px-4 rounded py-1.5 text-white'>{loading ? 'loading' : 'Yes'}</button>
                  <button onClick={callNoClick} className='bg-gray-500 px-4 rounded py-1.5 text-white'>No</button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GVDItemDeleteModal;