import React from "react";
import { useNavigate } from "react-router-dom";
import "./HomePage.css";

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex justify-center items-center overflow-hidden h-full">
      <div className="h-full flex justify-center flex-col items-center">
        <div>
          <text className="text">Not Logged In Home Page</text>
        </div>
        <div className="flex w-full justify-center gap-2 items-center flex-col">
          <button
            onClick={() => navigate("/SignUp")}
            className="w-1/2 bg-primary text-white rounded-full py-1 hover:bg-lightPrimary"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
