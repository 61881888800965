import React from "react";
import producthub_logo from "../../assets/images/logo_producthub.jpg";
import jira_logo from "../../assets/images/logo_jira.png";
import confluence_logo from "../../assets/images/logo_confluence.png";

const CouponAdministration = () => {
  return (

    <div style={{ backgroundColor: "white", minHeight: "100vh", padding: "20px" }}>
        <div style={{ margin: "20px", fontFamily: "Arial, sans-serif" }}>
        
        {/* Flexbox Table */}
        <div
            style={{
            display: "flex",
            flexDirection: "column",
            // border: "1px solid black",
            width: "90%",
            margin: "0 auto",
            }}
        >

        {/* Row 1: PH Logo */}
        <div
                style={{
                    textAlign: "center",
                    // borderBottom: "1px solid black"
                    padding: "10px",
                }}
            >
                <img
                src={producthub_logo}
                alt="ProductHub Logo"
                style={{
                    margin: "auto",
                    maxHeight: "200px"
                }}
            />
        </div>

        {/* Row 2: Page Heading */}
            <div
            style={{
                textAlign: "center",
                borderBottom: "0px solid black",
                padding: "10px"
            }}
            >
            <h1
            style={{
                margin: 0,
                fontFamily: "'Roboto', sans-serif", // Use Roboto font with a fallback to sans-serif
                fontSize: "2.5rem", // Explicitly set the font size
                fontWeight: "bold", // Explicitly set the font weight
                fontVariant: "small-caps", // Enable small caps
                textTransform: "uppercase", // Ensure all text is uppercase
                letterSpacing: "0.15rem", // Add spacing for a polished look
            }}
            >
            COUPONS
            </h1>
            </div>

        {/* Row 3: Page Summary */}
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
          <div style={{ flex: "25%", textAlign: "right", padding: "10px" }}>Page Summary:</div>
          <div style={{ flex: "75%", textAlign: "left", padding: "10px", fontWeight: "bold" }}>
            This page is used to View and Manage Coupons
          </div>
        </div>

        {/* Row 4: Page Details */}
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
          <div style={{ flex: "25%", textAlign: "right", padding: "10px" }}>Page Details:</div>
          <div style={{ flex: "75%", textAlign: "left", padding: "10px", fontWeight: "bold" }}>
            Lorem ipsum... explain what the page is for, couple of paragraphs...
            <br /><br />
            Consectetur adipiscing elit... this combined with the feature bullet list should explain the purpose and functionality of the page...
            <br /><br />
            Vivamus luctus urna... the level of detail does not have to be too much, there is a link to the Confluence page and Jira task for each page so...
          </div>
        </div>

        {/* Row 5: Page Functionality */}
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
            <div style={{ flex: "25%", textAlign: "right", padding: "10px" }}>
                Page Functionality:
            </div>
            <div style={{ flex: "75%", textAlign: "left", padding: "10px", fontWeight: "bold" }}>
                <ul style={{ paddingLeft: "0px", margin: 0 }}>
                    <li style={{ marginBottom: "3px" }}>
                        Feature 1: View Coupons
                    </li>
                    <li style={{ marginBottom: "3px" }}>
                        Feature 2: Create Coupons (With Admin Permissions)
                    </li>
                    <li style={{ marginBottom: "3px" }}>
                        Feature 3: Expire Coupons (With Admin Permissions)
                    </li>
                    <li style={{ marginBottom: "3px" }}>
                        Feature 4: View Historical Coupons
                    </li>
                </ul>
            </div>
        </div>

        {/* Row 6: Project Lead */}
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
          <div style={{ flex: "25%", textAlign: "right", padding: "10px" }}>Project Lead:</div>
          <div style={{ flex: "75%", textAlign: "left", padding: "10px", fontWeight: "bold" }}>
            Michael Duregon
          </div>
        </div>

        {/* Row 7: Confluence and Jira Links */}
        <div style={{ display: "flex", borderBottom: "0px solid black" }}>
        {/* Confluence Link */}
        <div style={{ flex: "50%", textAlign: "center", padding: "10px" }}>
            <a
                href="https://trickletreat.atlassian.net/wiki/x/AYClZg"
                target="_confluencewindow" // Same named target for all Confluence links
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                >
                <img
                    src={confluence_logo}
                    alt="Open documentation in Confluence"
                    style={{ margin: "auto", maxHeight: "35px", marginBottom: "10px" }}
                />
            </a>
            <br />
            <a
                href="https://trickletreat.atlassian.net/wiki/x/AYClZg"
                target="_confluencewindow" // Same named target
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                >
                Confluence Documentation
            </a>
        </div>

        {/* Jira Link */}
        <div style={{ flex: "50%", textAlign: "center", padding: "10px" }}>
            <a
                href="https://trickletreat.atlassian.net/browse/PHS-193"
                target="_jirawindow" // Same named target for all Jira links
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                >
                <img
                    src={jira_logo}
                    alt="Jira Logo"
                    style={{ margin: "auto", maxHeight: "40px", marginBottom: "10px" }}
                />
            </a>
            <br />
            <a
                href="https://trickletreat.atlassian.net/browse/PHS-193"
                target="_jirawindow" // Same named target
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                >
                PHS-193
            </a>
        </div>
        </div>

        {/* Row 8: Specification Details */}
        <div style={{ display: "flex" }}>
          <div style={{ flex: "25%", textAlign: "right", padding: "10px" }}>Created:</div>
          <div style={{ flex: "75%", textAlign: "left", padding: "10px", fontWeight: "bold" }}>
            Friday, 27th December 2024 by Michael Duregon.
          </div>
        </div>        
      </div>
    </div>
    </div>
    );
};

export default CouponAdministration;
