import React, { useContext, useState } from 'react';
import { SelectedGVDDetailContext } from '../context/SelectedGVDDetailContext';
import GVDItem from './GVDItem';
import GVDItemDetail from './GVDItemDetail';
import { ContextSelectedBrick } from '../../contexts/ContextSelectedBrick';
import { useAppSelector, useAppDispatch } from "../../../../../../store/store";
import {
	gvdValidate,
	fetchVariantData
} from "../../../../../../store/slices/gvdValidateSlice";

const GVDItemVariant = ({ group, variant }) => {
  const dispatch = useAppDispatch();
  const { details } = useAppSelector(gvdValidate);
  const { BRICK } = useContext(ContextSelectedBrick);

  const { selectedDetail, setSelectedDetail } = useContext(SelectedGVDDetailContext);

  const fetchData = async () => {
    dispatch(fetchVariantData(BRICK, group.GroupingID, variant.VariantID))
  };

  const isSelected = () => selectedDetail.GroupingID === group.GroupingID && selectedDetail.VariantID === variant.VariantID;

  const onClick = () => {
    setSelectedDetail({
      GroupingID: group.GroupingID, GroupingDesc: group.GroupingDesc,
      VariantID: variant.VariantID, VariantDesc: variant.VariantDesc,
      DetailID: '', DetailDesc: ''
    })
  };

  return (
    <GVDItem
      description={variant.VariantDesc}
      total={variant.Total}
      onClick={onClick}
      fetchData={fetchData}
      isSelected={isSelected}>

      {details.map((detail, key) => <GVDItemDetail key={key} group={group} variant={variant} detail={detail} />)}

    </GVDItem>
  );
};

export default GVDItemVariant;