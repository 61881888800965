import { isValid } from "gtin";
import React, { useState } from "react";
import { scanProduct } from "../../../api/BarcodeScan";
import DashboardContainer from "../../../dashboard-components/DashboardContainer";
import { useFormFieldUtils } from "../../../utils/FormHooks";
import ButtonShowParentModal from "./ButtonShowParentModal";
import PrefixClickable from "./ButtonShowPrefixModal";
import ButtonShowEditReclassifyModal from "./ButtonShowEditReclassifyModal";
import Loader from "../../../components/loader/Loader";
import editIcon from "../../../assets/icons/edit.png";

const ProductScanNew = () => {
  const isValidGTIN = (GTIN) => {
    try {
      return isValid(GTIN);
    } catch (error) {
      return false;
    }
  };

  const [apiRes, setApiRes] = useState(null);
  const [showClassifyModal, setShowClassifyModal] = useState(false);
  const [showCompromisedModal, setShowCompromisedModal] = useState(false);
  const [showPrefixModal, setPrefixModal] = useState(false);
  const GTINInput = useFormFieldUtils("");
  const [validGTIN, setValidGTIN] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const openClassifyModal = () => {
    setShowClassifyModal(true);
  };

  const closeClassifyModal = () => {
    setShowClassifyModal(false);
  };

  const openCompromisedModal = () => {
    setShowCompromisedModal(true);
  };

  const closeCompromisedModal = () => {
    setShowCompromisedModal(false);
  };

  const openPrefixModal = () => {
    setPrefixModal(true);
  };

  const closePrefixModal = () => {
    setPrefixModal(false);
  };

  const searchProduct = (event) => {
    event.preventDefault();
    setApiRes(null); 

    if (GTINInput.value.trim().length === 0) return;

    if (!isValidGTIN(GTINInput.value.trim())) {
      setValidGTIN(false);
      return;
    }

    setValidGTIN(true);
    callEndpoint(GTINInput.value.trim());
  };

  const callEndpoint = async (gtin) => {
    if (isLoading) return;
    setApiRes(null);
    setIsLoading(true);
    const apiRes = await scanProduct(gtin);
    console.log("this is product data".apiRes);
    setIsLoading(false);
    if (apiRes === false) return;
    setApiRes(apiRes.data);
    GTINInput.setValue("");
    GTINInput.focus();
  };

  const refreshPage = () => {
    callEndpoint(apiRes?.ProductInfo?.GTIN);
  };

  const formatSupplierAddress = (supplierData) => {
    if (!supplierData) return "NOT DEFINED";

    const { Address1, City, State, Postcode } = supplierData;

    let firstLine = Address1?.trim() ?? "NOT DEFINED";
    let secondLine = "";
    if (State || Postcode) {
      secondLine = [
        City?.trim(), // Add city if exists
        [State?.trim(), Postcode?.trim()].filter(Boolean).join(" "), // Join State and Postcode with space
      ]
        .filter(Boolean)
        .join(", "); // Only add comma if city exists
    }

    return [firstLine, secondLine];
  };

  // const isNewProduct = () => {
  //   if (apiRes === null) return {};
  //   if (apiRes?.ProductInfo?.isNew === true) {
  //     return { "new-record": true };
  //   }
  //   return { "existing-record": true };
  // };

  // const isNewSupplier = () => {
  //   if (apiRes === null) return {};
  //   if (apiRes?.SupplierInfo?.isNew === true) {
  //     return { "new-record": true };
  //   }
  //   return { "existing-record": true };
  // };

  // const isNewPrefix = () => {
  //   if (apiRes === null) return {};
  //   if (apiRes?.PrefixesInfo?.JustAdded.length > 0) {
  //     return { "new-record": true };
  //   }
  //   return { "existing-record": true };
  // };

  return (
    <>
      <DashboardContainer>
        <div className="bg-gray-100 p-3 rounded flex flex-col flex-grow">
          {/* Main row - all elements will have their tops aligned */}
          <div className="flex items-start gap-4 mb-4">
            {/* Brand Box */}
            <div className="bg-white flex flex-col w-[150px]">
              <span className="p-2 border-b font-bold bg-white">
                Brand Image
              </span>
              <div className="h-[125px] flex items-center justify-center">
                <img
                  src={apiRes?.ProductInfo?.BrandImgUrl}
                  width={150}
                  height={125}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>

            {/* Search Box */}
            <div className="flex-grow mb-4 w-full max-w-sm mx-auto">
              <form onSubmit={searchProduct} className="flex gap-2">
                <input
                  autoFocus
                  className="rounded py-2 w-full border border-gray px-2 bg-white"
                  ref={GTINInput.ref}
                  value={GTINInput.value}
                  onChange={GTINInput.onChange}
                  placeholder="Enter the Barcode/GTIN"
                  type="text"
                />
                <button className="rounded bg-primary py-1.5 px-3 text-white">
                  {isLoading ? <Loader /> : "Search"}
                </button>
              </form>
              {/* {!validGTIN && (
                <div className="mt-2">
                  <p className="text-red-500">Please, enter a valid barcode</p>
                </div>
              )} */}
              <div className="flex flex-col gap-2 w-full max-w-md mt-4">
              {!validGTIN && (
                <div className="mt-2">
                  <p className="text-red-500">Please, enter a valid barcode</p>
                </div>
              )}
              {apiRes?.ErrorMessages?.length > 0 ? (
                  // Show error messages if they exist
                  apiRes.ErrorMessages.map((error, index) => (
                    <div key={index} className="mt-2">
                      <p className="text-red-500">{error}</p>
                    </div>
                  ))
                ) : (
                  // Only show product info if there are no errors
                  apiRes && (
                    <>
                      <div className="flex gap-2">
                        <p className="w-full max-w-[100px] text-right">GTIN:</p>
                        <p className="p-0 m-0 font-bold">
                          {apiRes.ProductInfo.GTIN} (ID: {apiRes.ProductInfo.ID})
                        </p>
                      </div>
                      <div className="flex gap-2">
                        <p className="w-full max-w-[100px] text-right">Brand:</p>
                        <p className="font-bold">{apiRes.ProductInfo.Brand}</p>
                      </div>
                      <div className="flex gap-2">
                        <p className="w-full max-w-[100px] text-right">Description:</p>
                        <p className="font-bold">{apiRes.ProductInfo.Name}</p>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>

            {/* Product Box */}
            <div className="bg-white flex flex-col w-[150px]">
              <span className="p-2 border-b font-bold bg-white">
                Product Image
              </span>
              <div className="h-[125px] flex items-center justify-center">
                <img
                  src={apiRes?.ProductInfo?.ProductImgUrl}
                  width={150}
                  height={125}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-3 mt-3 flex-grow">
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <div className="border-b pb-3">
                <span className="text-sm font-semibold">Ownership</span>
              </div>
              <div className="body">
                {apiRes?.SupplierParent?.ID === null && (
                  <div className="grid grid-cols-12 gap-2 mt-3">
                    <p colSpan="2">
                      <ButtonShowParentModal
                        refreshCallback={refreshPage}
                        CompanyID={apiRes.SupplierInfo?.ID}
                      />
                    </p>
                  </div>
                )}
                {apiRes &&
                  apiRes.SupplierParent &&
                  apiRes.SupplierParent?.ID !== null && (
                    <div className="grid grid-cols-12 gap-2 mt-3">
                      <div className="col-span-12 flex gap-2 items-center">
                        <p className="w-full max-w-[70px] text-right">Name:</p>
                        <p className="font-bold">
                          {apiRes.SupplierParent?.Name}
                        </p>
                      </div>
                      <div className="col-span-12 flex gap-2 items-start">
                        <p className="w-full max-w-[70px] text-right my-0">
                          Address:
                        </p>
                        <div className="font-bold">
                          {formatSupplierAddress(apiRes?.SupplierParent).map(
                            (line, index) => (
                              <p key={index} className="p-0 m-0">
                                {line}
                              </p>
                            )
                          )}
                        </div>
                      </div>
                      <div className="col-span-12 flex gap-2 items-center">
                        <p className="w-full max-w-[70px] text-right">
                          Ownership:
                        </p>
                        <p className="font-bold">
                          {apiRes.SupplierParent?.Ownership ?? "NOT DEFINED"}
                        </p>
                      </div>
                      <div className="col-span-12 flex gap-2 items-center">
                        <p className="w-full max-w-[70px] text-right">
                          Entity GLN:
                        </p>
                        <p className="font-bold">
                          {apiRes.SupplierParent?.EntityGLN ?? "NOT DEFINED"}
                        </p>
                      </div>
                      <div className="col-span-12 flex gap-2 items-center">
                        <p className="w-full max-w-[70px] text-right">Tax:</p>
                        <p className="font-bold">
                          {apiRes.SupplierParent?.Tax ?? "NOT DEFINED"}
                        </p>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <div className="border-b pb-3 flex justify-between items-center">
                <span className="text-sm font-semibold">
                  GS1 Registration Details
                </span>
                {/* <ButtonShowEditCompromisedModal
                  refreshCallback={refreshPage}
                  GTIN={apiRes?.ProductInfo?.GTIN}
                  openCompromisedModal={openCompromisedModal}
                  closeCompromisedModal={closeCompromisedModal}
                  showCompromisedModal={showCompromisedModal}
                  disabled={!apiRes}
                /> */}
              </div>
              {/* </div><div className="body mt-3"> */}
              <div className="body">
                {apiRes !== null && !apiRes?.ErrorMessages?.length && (
                  <div className="grid grid-cols-12 gap-2 mt-3">
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">Name:</p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.SupplierInfo?.Name ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-start">
                      <p className="w-full max-w-[70px] text-right my-0">
                        Address:
                      </p>
                      <div className="font-bold">
                        {formatSupplierAddress(apiRes?.SupplierInfo).map(
                          (line, index) => (
                            <p key={index} className="p-0 m-0">
                              {line}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">
                        Ownership:
                      </p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.SupplierInfo?.Ownership ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">
                        Entity GLN:
                      </p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.SupplierInfo?.EntityGLN ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">Tax:</p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.SupplierInfo?.Tax ?? "NOT DEFINED"}
                      </p>
                    </div>
                    {/* {apiRes?.PrefixesInfo?.Existing.length > 0 && (
                      <div className="col-span-12 flex gap-2 items-center">
                        <p className="w-full max-w-[70px] text-right">
                          Prefix:
                        </p>
                        {apiRes.PrefixesInfo.Existing.map((prefix, key) => (
                          <div key={key} className="flex flex-col items-center">
                            <span className="font-bold">
                              <PrefixClickable
                                showPrefixModal={showPrefixModal}
                                closePrefixModal={closePrefixModal}
                                openPrefixModal={openPrefixModal}
                                prefix={prefix}
                              />
                            </span>
                          </div>
                        ))}
                      </div>
                    )} */}
                    {apiRes?.LicensesInfo?.Existing.length > 0 && (
                      <div className="col-span-12 flex gap-2">
                        <p className="w-[70px] text-right my-0 shrink-0">
                          Licenses:
                        </p>
                        <div className="flex flex-col gap-1 pl-0">
                          {apiRes.LicensesInfo.Existing.map((license, key) => (
                            <div key={key} className="flex font-bold">
                              <PrefixClickable
                                showPrefixModal={showPrefixModal}
                                closePrefixModal={closePrefixModal}
                                openPrefixModal={openPrefixModal}
                                prefix={license}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {apiRes?.LicensesInfo?.JustAdded.length > 0 && (
                  <div>
                    {apiRes.LicensesInfo.JustAdded.map((prefix, key) => (
                      <div key={key} className="flex gap-2 items-center">
                        <p className="font-bold">{key === 0 ? "New" : null}</p>
                        <p>{prefix}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <div className="border-b pb-3">
                <span className="text-sm font-semibold">Product Details</span>
              </div>
              <div className="body">
                {apiRes !== null && !apiRes?.ErrorMessages?.length && (
                  <div className="grid grid-cols-12 gap-2 mt-3">
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">Source:</p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.ProductInfo?.Source}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">Origin:</p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.ProductInfo?.Origin}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">
                        Percentage:
                      </p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.ProductInfo?.Percentage}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-3 flex-grow mt-5">
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <div className="border-b pb-3 flex justify-between items-center">
                <span className="text-sm font-semibold">
                  GS1 Global Product Classification
                </span>
                <ButtonShowEditReclassifyModal
                  productInfo={apiRes?.ProductInfo}
                  refreshCallback={refreshPage}
                  showClassifyModal={showClassifyModal}
                  openClassifyModal={openClassifyModal}
                  closeClassifyModal={closeClassifyModal}
                  disabled={!apiRes}
                />
              </div>
              <div className="body">
                {apiRes !== null && !apiRes?.ErrorMessages?.length && (
                  <div className="grid grid-cols-12 gap-2 mt-3">
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">Segment:</p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.BrickInfo?.Segment ?? "NOT DEFINED"} -{" "}
                        {apiRes.BrickInfo?.SegmentDesc ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">Family:</p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.BrickInfo?.Family ?? "NOT DEFINED"} -{" "}
                        {apiRes.BrickInfo?.FamilyDesc ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">Class:</p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.BrickInfo?.Class ?? "NOT DEFINED"} -{" "}
                        {apiRes.BrickInfo?.ClassDesc ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">Brick:</p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.BrickInfo?.Brick ?? "NOT DEFINED"} -{" "}
                        {apiRes.BrickInfo?.BrickDesc ?? "NOT DEFINED"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <div className="border-b pb-3 flex justify-between items-center">
                <span className="text-sm font-semibold">
                  Grouping Variant Detail Classification
                </span>
                <button className="hover:bg-gray-100 p-1 rounded">
                  <img src={editIcon} alt="Edit" width="16" height="16" />
                </button>
              </div>

              <div className="body">
                {apiRes !== null && !apiRes?.ErrorMessages?.length && (
                  <div className="grid grid-cols-12 gap-2 mt-3">
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">
                        Grouping:
                      </p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.GroupVariantDetail?.Group ?? "NOT DEFINED"} -{" "}
                        {apiRes.GroupVariantDetail?.GroupDesc ?? "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">Variant:</p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.GroupVariantDetail?.Variant ?? "NOT DEFINED"} -{" "}
                        {apiRes.GroupVariantDetail?.VariantDesc ??
                          "NOT DEFINED"}
                      </p>
                    </div>
                    <div className="col-span-12 flex gap-2 items-center">
                      <p className="w-full max-w-[70px] text-right">Detail:</p>
                      <p className="p-0 m-0 font-bold">
                        {apiRes.GroupVariantDetail?.Detail ?? "NOT DEFINED"} -{" "}
                        {apiRes?.GroupVariantDetail?.DetailDesc ??
                          "NOT DEFINED"}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bg-white shadow-lg p-3 rounded-xl">
              <div className="border-b pb-3 flex justify-between items-center">
                <span className="text-sm font-semibold">
                  GS1 Product Attributes
                </span>
                <button className="hover:bg-gray-100 p-1 rounded">
                  <img src={editIcon} alt="Edit" width="16" height="16" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </DashboardContainer>
    </>
  );
};

export default ProductScanNew;
